import { defineStore } from 'pinia';
import type { Story } from '~/interfaces/Story';

export const useStoryStore = defineStore('story', () => {
  const stories = ref<Story[]>([]);
  const story = ref<Story>();

  const setStories = (data: Story[]) => {
    stories.value = data;
  };

  const setStory = (data: Story) => {
    story.value = data;
  };

  return {
    stories,
    story,
    setStories,
    setStory,
  };
});
